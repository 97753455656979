var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"position-relative d-flex",staticStyle:{"min-height":"300px","overflow-x":"auto"}},[_vm._l((_vm.apartments),function(apartment,index){return _c('div',{key:apartment.id,staticClass:"d-flex",staticStyle:{"margin-bottom":"50px"}},[_c('div',{staticClass:"vertical"},[_c('h5',[_vm._v(" "+_vm._s(_vm.$t("object.level"))+" ")])]),_c('div',{staticClass:"block"},[_c('div',{staticClass:"list-number"},_vm._l((_vm.levelLarge(index)),function(level){return _c('div',{key:level,staticClass:"counter"},[_vm._v(" "+_vm._s(level)+" ")])}),0),_vm._l((apartment.blocks),function(value){return [(_vm.showBlock(value.blockActive))?_c('div',{key:value.id,staticClass:"d-flex flex-column position-relative"},[_c('div',{staticClass:"header"},[_c('div',[_vm._v(_vm._s(apartment.name)+" / "+_vm._s(value.name))])]),_c('div',{staticClass:"item",staticStyle:{"margin-right":"30px"}},_vm._l((_vm.fixFloors(value.floors)),function(item){return _c('div',{key:item.name},[(item.apartments.length)?_c('div',{staticClass:"d-flex flex-nowrap block-content"},_vm._l((item.apartments),function(elem){return _c('div',{key:elem.id,staticClass:"block-item",class:{
                    'inactive-apartment':
                      _vm.hasQuery &&
                      _vm.inactiveApartment(
                        elem.apartmentActive,
                        item.floorActive,
                        value.blockActive
                      ),
                  }},[_c('div',{staticClass:"square",class:[
                      _vm.status(elem.order.status).class,
                      elem.is_sold ? '' : 'disable',
                    ],on:{"click":function($event){return _vm.showExpressSidebar(
                        elem,
                        item.floorActive,
                        value.blockActive
                      )}}},[_c('div',{staticClass:"square-header"},[_c('p',{staticClass:"apartment-number"},[_vm._v("Кв. № "+_vm._s(elem.number))]),(elem.is_promo)?_c('div',{staticClass:"h-auto d-flex apartment-promo-icon"},[_c('img',{attrs:{"src":require("../../../../assets/icons/bonuses.svg"),"alt":""}})]):_vm._e()]),_c('div',{staticClass:"square-body"},[(!elem.is_sold)?_c('h5',[[_c('span',{staticClass:"apartment-status"},[_vm._v(" "+_vm._s(_vm.$t("not_for_sale"))+" ")])]],2):_c('h5',[(_vm.status(elem.order.status).statusText)?[_c('span',{staticClass:"apartment-status"},[_vm._v(" "+_vm._s(_vm.status(elem.order.status).statusText)+" ")])]:[_c('span',{staticClass:"apartment-price"},[_vm._v(_vm._s(_vm.price(elem.prices.price, 2))+" сум")])]],2)]),_c('div',{staticClass:"square-footer"},[_c('p',{staticClass:"apartment-area"},[_vm._v(" "+_vm._s(elem.plan.area)+" m"),_c('sup',[_vm._v("2")])]),(
                          elem.order.status !== 'sold' &&
                          elem.is_sold &&
                          !_vm.isHidePrice
                        )?_c('p',{staticClass:"apartment-square-price"},[_vm._v(" "+_vm._s(_vm.price(elem.prices.price_m2))+" "+_vm._s(_vm.$t("ye"))+"/m"),_c('sup',[_vm._v("2")])]):_vm._e()])])])}),0):_c('div',{staticClass:"block-item"},[_c('div',{staticClass:"square"})])])}),0)]):_vm._e()]})],2)])}),_c('b-overlay',{staticStyle:{"z-index":"2222"},attrs:{"show":_vm.loading,"no-wrap":"","opacity":"0"},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-center w-100"},[_c('div',{staticClass:"lds-ellipsis"},[_c('div'),_c('div'),_c('div'),_c('div')])])]},proxy:true}])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }