import { render, staticRenderFns } from "./TabContractComments.vue?vue&type=template&id=581814a0&scoped=true"
import script from "./TabContractComments.vue?vue&type=script&lang=js"
export * from "./TabContractComments.vue?vue&type=script&lang=js"
import style0 from "./TabContractComments.vue?vue&type=style&index=0&id=581814a0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "581814a0",
  null
  
)

export default component.exports