<script>
import { makeProp as p } from "@/util/props";
import { PROP_TYPE_OBJECT, PROP_TYPE_BOOLEAN } from "@/constants/props";
import { formatToPrice } from "@/util/reusable";

export default {
  name: "ChPaymentResult",
  props: {
    order: p(PROP_TYPE_OBJECT, {}),
    result: p(PROP_TYPE_BOOLEAN(), false),
  },
  methods: {
    prettier: formatToPrice,
  },
  computed: {
    discount() {
      if (this.order.calculation.discount === "other") {
        return this.paymentOptions.find((el) => el.type === "other");
      }
      return this.paymentOptions.find(
        (el) => el.id === this.order.calculation.discount
      );
    },
    installment() {
      return this.discount.installment_months.find(
        (el) => el.id === this.order.calculation.currentInstallment
      );
    },
    priceCalculations() {
      if (this.order.calculation.type === "installment")
        return this.installment.price_calculations;
      return this.discount.price_calculations;
    },
    prepayAmount() {
      return this.priceCalculations.initial_payment;
    },
    paymentOptions() {
      let ds;
      if (this.order.calculation.type === "promo")
        ds = this.order.calcResult.discounts.filter((d) => d.type === "promo");
      else {
        ds = this.order.calcResult.discounts.filter((d) => d.type !== "promo");
        if (this.order.calculation.type === "installment") {
          ds = ds.filter((el) => el.id);
        }
      }
      return ds.map((d, index) => {
        let text = this.$t("apartments.view.variant");
        if (d.type === "other") {
          return {
            ...d,
            text: this.$t("apartments.view.other_variant"),
            value: "other",
            id: "other",
          };
        }
        if (d.type === "promo") {
          text += ` ${this.$t("promo.by_promo")}`;
        }
        text += `  ${index + 1} - ${d.prepay}%`;

        return {
          text,
          value: d.id,
          ...d,
        };
      });
    },
  },
};
</script>

<template>
  <div class="ch-payment-result">
    <div class="ch-payment-information">
      <span class="gray-400">{{ $t("starting_price") }}</span>
      <span class="ch-price-cell gray-400"
        >{{ prettier(order.calcResult.expensive_apartment_price, 2) }}
        {{ $t("ye") }}</span
      >
      <span class="gray-400">{{ $t("price_m") }}<sup>2</sup></span>
      <span class="ch-price-cell gray-400"
        >{{ prettier(priceCalculations.per_square_price, 2) }}
        {{ $t("ye") }}</span
      >

      <span class="gray-400">{{ $t("contracts.view.initial_fee") }}</span>
      <span class="ch-price-cell gray-400"
        >{{ prettier(priceCalculations.initial_payment, 2) }}
        {{ $t("ye") }}</span
      >

      <span class="gray-400">{{ $t("remainder") }}</span>
      <span class="ch-price-cell gray-400"
        >{{
          prettier(
            priceCalculations.transaction_price -
              priceCalculations.initial_payment,
            2
          )
        }}
        {{ $t("ye") }}</span
      >

      <span class="teal-500">{{ $t("total_discount") }}</span>
      <span class="ch-price-cell teal-500"
        >{{
          prettier(
            order.calcResult.expensive_apartment_price -
              priceCalculations.transaction_price,
            2
          )
        }}
        {{ $t("ye") }}</span
      >
      <template v-if="!result">
        <span class="teal-500">{{ $t("discount_per_m") }}<sup>2</sup></span>
        <span class="ch-price-cell teal-500">
          {{
            prettier(
              (order.calcResult.expensive_apartment_price -
                priceCalculations.transaction_price) /
                order.calcResult.sellable_area,
              0
            )
          }}

          {{ $t("ye") }}
        </span>
      </template>
    </div>
    <div class="ch-border-dashed" />
    <div class="ch-total-price">
      <span class="violet-500 ch-price-total-cell">{{ $t("total") }}</span>
      <span class="ch-price-cell violet-700 ch-price-total-cell ch-price-total">
        {{ prettier(priceCalculations.transaction_price, 2) }}
        {{ $t("ye") }}
      </span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.ch-payment-result {
  border-radius: 2rem;
  border: 2px solid var(--gray-200);
  padding: 1.5rem;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  height: fit-content;

  .ch-payment-information {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
    justify-items: stretch;
    row-gap: 1.5rem;
  }

  .ch-border-dashed {
    border-top: 2px dashed var(--gray-200);
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .ch-total-price {
    display: grid;
    grid-template-columns: auto auto;
    align-items: end;
  }

  .ch-price-cell {
    justify-self: end;
  }

  .ch-price-total-cell {
    align-self: end;
  }

  .ch-price-total {
    font-size: 24px;
    line-height: 30px;
  }
}
</style>
