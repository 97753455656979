var render = function render(){var _vm=this,_c=_vm._self._c;return _c('validation-observer',{ref:_vm.calcRef,staticClass:"ch-calculator-wrapper",attrs:{"tag":"div"}},[_c('validation-provider',{staticClass:"cw-payment-option full",attrs:{"rules":"required","name":`${_vm.$t('enter_discount')}`},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('x-form-select',{attrs:{"value":_vm.order.calculation.type,"error":!!errors[0],"options":_vm.typeOptions,"placeholder":_vm.$t('plan_type'),"value-field":"value"},on:{"change":(e) =>
          _vm.emitEdit({
            field: 'type',
            value: e,
          })}})]}}])}),(_vm.paymentOptions.length)?_c('validation-provider',{staticClass:"cw-payment-option full",attrs:{"rules":"required","name":`${_vm.$t('enter_discount')}`},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('x-form-select',{attrs:{"value":_vm.order.calculation.discount,"bilingual":true,"error":!!errors[0],"options":_vm.paymentOptions,"placeholder":_vm.$t('enter_discount'),"value-field":"id"},on:{"change":(e) =>
          _vm.emitEdit({
            field: 'discount',
            value: e,
          })}})]}}],null,false,1397212445)}):_vm._e(),(_vm.order.calculation.type === 'custom')?_c('validation-provider',{staticClass:"cw-monthly-payment full",attrs:{"rules":_vm.order.calculation.prepay < 100
        ? 'required|min_value:1'
        : 'required|min_value:0',"name":`${_vm.$t('installment')}`},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('x-form-input',{staticClass:"w-100",attrs:{"value":_vm.discount.months,"type":"number","currency":`${_vm.$t('month_lowercase')}`,"label":true,"max":360,"error":!!errors[0],"placeholder":`${_vm.$t('installment')}`,"autocomplete":"off"},on:{"input":(e) =>
          _vm.emitEditLazy({
            field: 'months',
            value: e,
          })}})]}}],null,false,72350924)}):_vm._e(),(_vm.order.calculation.type === 'installment')?_c('validation-provider',{staticClass:"cw-payment-option full",attrs:{"rules":"required","name":`${_vm.$t('enter_discount')}`},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('x-form-select',{attrs:{"value":_vm.order.calculation.currentInstallment,"error":!!errors[0],"options":_vm.installmentOptions,"placeholder":_vm.$t('installment months'),"value-field":"value"},on:{"change":(e) =>
          _vm.emitEdit({
            field: 'installment',
            value: e,
          })}})]}}],null,false,2711047006)}):_vm._e(),(_vm.order.calculation.type === 'promo')?_c('validation-provider',{staticClass:"cw-payment-option full",attrs:{"rules":"required","name":`${_vm.$t('enter_discount')}`}},[_c('x-form-input',{staticStyle:{"width":"100%"},attrs:{"value":_vm.discount.installment_months + ' ' + _vm.$t('month'),"placeholder":`${_vm.$t('installment')}`,"value-field":"value","readonly":true}})],1):_vm._e(),_c('validation-provider',{staticClass:"cw-prepayment",attrs:{"rules":"required|min_value:0","name":`${_vm.$t('prepayment')}`},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('y-form-input',{staticClass:"w-100",attrs:{"value":_vm.priceCalculations.initial_debt_percentage,"type":"number","currency":"%","precision":2,"label":true,"max":100,"error":!!errors[0],"placeholder":`${_vm.$t('prepayment')}`},on:{"input":(e) =>
          _vm.emitEdit({
            field: 'initial_payment_percentage',
            value: e,
          })}})]}}])}),_c('validation-provider',{staticClass:"cw-initial-fee",attrs:{"rules":"required|min_value:0","name":`${_vm.$t('payments.initial_fee')}`},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('y-form-input',{staticClass:"w-100",attrs:{"value":_vm.prepayAmount,"type":"number","currency-symbol":true,"label":true,"precision":2,"error":!!errors[0],"placeholder":`${_vm.$t('payments.initial_fee')}`},on:{"input":(e) =>
          _vm.emitEdit({
            field: 'initial_payment',
            value: e,
          })}})]}}])}),(_vm.order.calculation.discount === 'other')?_c('validation-provider',{staticClass:"cw-starting-price",attrs:{"rules":"required|min_value:0","name":`${_vm.$t('starting_price')}`},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('y-form-input',{staticClass:"w-100",attrs:{"value":_vm.priceCalculations.apartment_price,"type":"number","label":true,"precision":2,"currency-symbol":true,"error":!!errors[0],"placeholder":`${_vm.$t('starting_price')}`},on:{"input":(e) =>
          _vm.emitEdit({
            field: 'apartment_price',
            value: e,
          })}})]}}],null,false,1526798020)}):_vm._e(),(_vm.order.calculation.discount === 'other')?_c('validation-provider',{staticClass:"cw-price-m2",attrs:{"rules":"required|min_value:0","name":`${_vm.$t('price_m2')}`},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('y-form-input',{staticClass:"w-100",attrs:{"type":"number","currency-symbol":true,"label":true,"precision":2,"error":!!errors[0],"placeholder":`${_vm.$t('price_m2')}`},on:{"input":(e) =>
          _vm.emitEdit({
            field: 'apartment_price_m2',
            value: e,
          })},model:{value:(_vm.m2Price),callback:function ($$v) {_vm.m2Price=$$v},expression:"m2Price"}})]}}],null,false,3656633399)}):_vm._e(),_c('validation-provider',{staticClass:"cw-total-discount",attrs:{"name":`${_vm.$t('total_discount')}`},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('y-form-input',{staticClass:"w-100",attrs:{"type":"number","currency-symbol":true,"label":true,"precision":2,"error":!!errors[0],"placeholder":`${_vm.$t('total_discount')}`,"value":_vm.order.calcResult.discount_amount},on:{"input":(e) =>
          _vm.emitEdit({
            field: 'total_discount',
            value: e,
          })}})]}}])}),_c('validation-provider',{staticClass:"cw-discount-per-m2",attrs:{"name":`${_vm.$t('discount_per_m2')}`},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('y-form-input',{staticClass:"w-100",attrs:{"type":"number","currency-symbol":true,"label":true,"precision":2,"error":!!errors[0],"placeholder":`${_vm.$t('discount_per_m2')}`,"value":_vm.m2discount},on:{"input":(e) =>
          _vm.emitEdit({
            field: 'm2_discount',
            value: e,
          })}})]}}])}),_c('validation-provider',{staticClass:"cw-first-payment-date full",attrs:{"name":`${_vm.$t('first_payment_date')}`,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('base-date-picker',{staticClass:"data-picker w-100",attrs:{"value":_vm.order.calcResult.initial_payment_date,"range":false,"error":!!errors[0],"warning":_vm.oldDate || _vm.nextYear,"format":"DD.MM.YYYY","placeholder":`${_vm.$t('first_payment_date')}`,"icon-fill":"var(--violet-600)"},on:{"input":(e) =>
          _vm.emitEdit({
            field: 'initial_payment_date',
            value: e,
          })}})]}}])}),(_vm.oldDate)?_c('div',{staticClass:"full",staticStyle:{"padding-left":"17px","color":"orange"}},[_vm._v(" "+_vm._s(_vm.$t("passedDateError"))+" ")]):_vm._e(),(_vm.nextYear)?_c('div',{staticClass:"full",staticStyle:{"padding-left":"17px","color":"orange"}},[_vm._v(" "+_vm._s(_vm.$t("nextYearSelectedError"))+" ")]):_vm._e(),_c('validation-provider',{staticClass:"cw-monthly-payment-date full",attrs:{"name":`${_vm.$t('payment_date')}`,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('base-date-picker',{staticClass:"data-picker w-100",attrs:{"value":_vm.order.calcResult.monthly_payment_date,"range":false,"error":!!errors[0],"format":"DD.MM.YYYY","placeholder":`${_vm.$t('payment_date')}`,"icon-fill":"var(--violet-600)"},on:{"input":(e) =>
          _vm.emitEdit({
            field: 'monthly_payment_date',
            value: e,
          })}})]}}])}),(_vm.showModal)?_c('x-modal-center',{attrs:{"bilingual":true,"cancel-button-text":"cancel","apply-button-class":"w-100","cancel-button-class":"w-100","apply-button-text":"apply","footer-class":"d-flex justify-content-between x-gap-1"},on:{"close":function($event){_vm.showModal = false},"cancel":function($event){_vm.showModal = false},"apply":_vm.emitModalEdit},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h3',{staticClass:"x-font-size-24px font-craftworksans color-gray-600"},[_vm._v(" "+_vm._s(_vm.$t("Изменение Параметров"))+" ")])]},proxy:true},{key:"body",fn:function(){return [_c('div',{staticClass:"ch-comment-body mb-4"},[_c('div',{staticClass:"ch-comment-body-comment mb-2"},[_vm._v(" "+_vm._s(_vm.modalText)+" ")]),_c('x-form-input',{staticClass:"w-100",attrs:{"type":"number","currency-symbol":true,"label":true,"precision":2,"value":_vm.modalValue},on:{"input":(e) => (_vm.modalValue = e)}})],1)]},proxy:true}],null,false,2854915938)}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }